/*
Notes on js Date object
Date.parse() returns milliseconds since 1. jan 1970 00:00 !UTC!
by converting to UTC from strings specifying another time zone.
aDate.toJSON() returns JSON serialized time, i.e. UTC!
Date.parse("2022-08-23T17:30:00.000+02:00") -> 1663947000000 (17:30 CEST)
new Date(1663947000000).toJSON() -> 2022-08-23T15:30:00.000Z (15:30 UTC)
*/

import type Events from "@/types/Events";
import {
  type Event as GoogleEvent,
  type EventDateTime as GoogleEventDateTime,
} from "@/types/GoogleCalendarEvent.v3";
import {
  EventTypes,
  type BiblePassage,
  type Event,
  type Calendars,
} from "@/types/Events";
import PassageParser from "@/utils/PassageParser";

let calendarEvents = [] as Array<Event>;
async function getCalendarEvents(apiKey: string) {
  if (calendarEvents && calendarEvents.length > 0) {
    console.info("Importing events from cache");
    return calendarEvents;
  } else {
    console.info("Importing events from Google Calendar");
    const now = new Date();
    const fourHoursAgo = new Date().setHours(now.getHours() - 4);
    const in360Days = new Date().setDate(now.getDate() + 360);
    const googleCalendars: { [key in Calendars]: string } = {
      Bede: "ed45da04295479bd70cef4d5839621d36af616752b230035f195dea0c565a7fa%40group.calendar.google.com",
      "Bede Ung":
        "12aee95a916fda1f23ca59d540a37939b7d72af6c725c3cb49ce3c99875b72a7%40group.calendar.google.com",
    };

    const apiBaseUrl = "https://www.googleapis.com/calendar/v3/calendars/";

    const responsesArray = await Promise.all(
      Object.entries(googleCalendars).map(async ([name, calendar]) => {
        console.log("fetching", name, "from", calendar);
        return [
          [name],
          await fetch(
            `${apiBaseUrl}${calendar}/events` +
              "?singleEvents=true" +
              "&orderBy=startTime" +
              `&timeMin=${new Date(fourHoursAgo).toJSON()}` +
              `&timeMax=${new Date(in360Days).toJSON()}` +
              `&key=${apiKey}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json;charset=utf-8" },
            }
          ),
        ];
      })
    );

    const responseMap: { [key in Calendars]: Response } =
      Object.fromEntries(responsesArray);
    const responses: Array<Response> = Object.values(responseMap);

    console.debug(
      "responses.ok",
      responses.every((res) => res.ok)
    );

    if (!responses.every((res) => res.ok)) {
      responses.forEach((response) => {
        console.warn(
          "Unable to fetch all calendar events from Google Calendar. Received:\n",
          response.statusText,
          "\nFrom:",
          response.url
        );
      });
      return [];
    } else {
      calendarEvents = (
        await Promise.all(
          Object.entries(responseMap).map(async ([calendar, response]) => {
            const eventListJSON = await response.json();
            return eventListJSON.items.map((googleEvent: GoogleEvent) => {
              const bedeEvent = {} as Event;
              if (googleEvent.id) {
                bedeEvent.eventId = googleEvent.id;
              }
              bedeEvent.calendar = calendar as Calendars;

              // timestamp: string;
              // timestampEnd?: string;
              // timeType?: "timestamp" | "date" | "time-span" | "day-span";
              if (googleEvent.start && googleEvent.end && googleEvent.id) {
                const time = parseTime(
                  googleEvent.start,
                  googleEvent.end,
                  googleEvent.id
                );
                bedeEvent.timeType = time.timeType;
                bedeEvent.timestamp = time.timestamp;
                time.timestampEnd &&
                  (bedeEvent.timestampEnd = time.timestampEnd);
              } else {
                console.warn(
                  "Did not receive Google Calendar response for event.start, event.end and event.id"
                );
              }

              // eventType: EventType
              if (googleEvent.summary) {
                //could be optimized, currently runs O(n^2) with .includes looping summary for each eventType
                //maybe use a heap/dictionary of eventType to get linear time lookup
                //https://stackoverflow.com/a/21970204
                const summary = googleEvent.summary.toLowerCase();
                const possibleEventTypes = EventTypes.filter(
                  (eventType) =>
                    summary.includes(eventType.toLocaleLowerCase()) ||
                    (eventType === "Ekstern" &&
                      (summary.includes("innlandskirken") ||
                        summary.includes("metodistkirken") ||
                        summary.includes("frelsesarmeen")))
                );
                if (possibleEventTypes) {
                  if (possibleEventTypes.length > 1) {
                    console.warn(
                      "More than 1 possible event type |event.id",
                      googleEvent.id
                    );
                  }
                  bedeEvent.eventType = possibleEventTypes[0] || "Annet";
                }
              } else
                console.warn(
                  "Did not receive Google Calendar response for event.summary"
                );

              // eventName?: string;
              if (googleEvent.summary) {
                bedeEvent.eventName = googleEvent.summary;
              }

              const tempPassages = [] as Array<BiblePassage>;
              const tempImg = {} as {
                src: string;
                alt?: string;
                caption?: string;
                captionHref?: string;
              };

              googleEvent.description
                ?.trim()
                .split("\n")
                .forEach((line: string, index: number) => {
                  line = line.trim();

                  let matches;
                  // title?: string;
                  if (index === 0 && (matches = line.match(/^"(.*)"$/))) {
                    bedeEvent.title = matches[1].trim();
                  } else if ((matches = line.match(/^Tekst:\s?(.*)$/i))) {
                    const passage = PassageParser(
                      matches[1],
                      googleEvent.id || ""
                    );
                    if (passage) {
                      tempPassages.push(...passage);
                    }
                  } else if ((matches = line.match(/^Koordinator:\s?(.*)$/i))) {
                    // coordinator?: string;
                    bedeEvent.coordinator = matches[1].trim();
                  } else if ((matches = line.match(/^Arrangør:\s?(.*)$/i))) {
                    // organizer?: string;
                    bedeEvent.organizer = matches[1].trim();
                  } else if ((matches = line.match(/^Bilde:\s?(.*)$/i))) {
                    // img.src: string;
                    tempImg.src = `/img/${matches[1].trim()}`;
                  } else if (
                    (matches = line.match(/^(?:Alt|Bildetekst):\s?(.*)$/i))
                  ) {
                    // img.alt: string;
                    tempImg.alt = matches[1].trim();
                  } else if ((matches = line.match(/^Fotograf:\s?(.*)$/i))) {
                    // img.caption: string;
                    tempImg.caption = matches[1].trim();
                  } else if ((matches = line.match(/^Kilde:\s?(.*)$/i))) {
                    // img.captionHref: string;
                    tempImg.captionHref = matches[1].trim();
                  } else if (
                    (matches = line.match(
                      /^(?:Link|Lenke):\s?(.*)((?:vipps|https?):\/\/.*)$/i
                    ))
                  ) {
                    // externalLink?: {
                    //   label: string;
                    //   href: string;
                    // };
                    if (bedeEvent.externalLinks) {
                      bedeEvent.externalLinks.push({
                        label: matches[1].trim(),
                        href: matches[2].trim(),
                      });
                    } else {
                      bedeEvent.externalLinks = [
                        {
                          label: matches[1].trim(),
                          href: matches[2].trim(),
                        },
                      ];
                    }
                  } else {
                    // description: string;
                    if (bedeEvent.description) {
                      bedeEvent.description += `\n${line}`;
                    } else {
                      bedeEvent.description = line;
                    }
                  }
                });
              if (bedeEvent.description) {
                bedeEvent.description = bedeEvent.description.replace(
                  /\n\s*\n/g,
                  "\n\n"
                );
                bedeEvent.description = bedeEvent.description.replace(
                  /\n+$/,
                  ""
                );
              }

              if (tempPassages.length > 0) {
                bedeEvent.biblePassages = tempPassages;
              }
              if (tempImg) {
                bedeEvent.img = tempImg;
              }

              return bedeEvent;
            }) as Events;
          })
        )
      )
        .flat(1)
        .sort(
          (eventLeft, eventRight) =>
            Date.parse(eventLeft.timestamp) - Date.parse(eventRight.timestamp)
        );
      return calendarEvents;
    }
  }
}

type BedeEventDateTime = {
  timeType: "timestamp" | "date" | "time-span" | "day-span";
  timestamp: string;
  timestampEnd?: string;
};
function parseTime(
  googleEventStart: GoogleEventDateTime,
  googleEventEnd: GoogleEventDateTime,
  eventId: string
): BedeEventDateTime {
  const bedeEvent = {} as BedeEventDateTime;
  if (googleEventStart.dateTime && googleEventEnd.dateTime) {
    if (googleEventStart.dateTime === googleEventEnd.dateTime) {
      bedeEvent.timeType = "timestamp";
    } else {
      bedeEvent.timeType = "time-span";
    }
  } else if (googleEventStart.date && googleEventEnd.date) {
    // Google treats an all-day event as lasting until the next date inclusively
    // We need to exclude that last day from the end date
    // 1d = 1000ms * 60s * 60m * 24h = 86400000
    googleEventEnd.date = new Date(
      Date.parse(googleEventEnd.date) - 86400000
    ).toJSON();

    if (googleEventStart.date === googleEventEnd.date) {
      bedeEvent.timeType = "date";
    } else {
      bedeEvent.timeType = "day-span";
    }
  } else if (
    googleEventStart.dateTime &&
    googleEventEnd.dateTime === undefined
  ) {
    bedeEvent.timeType = "timestamp";
    console.warn(
      "Unexpected date formatting: start.dateTime is present, but end.dateTime is missing |event.id",
      eventId
    );
  } else if (googleEventStart.date && googleEventEnd.date === undefined) {
    bedeEvent.timeType = "date";
    console.warn(
      "Unexpected date formatting: start.date is present, but end.date is missing |event.id",
      eventId
    );
  }

  if (bedeEvent.timeType === "timestamp" && googleEventStart.dateTime) {
    bedeEvent.timestamp = new Date(
      Date.parse(googleEventStart.dateTime)
    ).toJSON();
  } else if (
    bedeEvent.timeType === "time-span" &&
    googleEventStart.dateTime &&
    googleEventEnd.dateTime
  ) {
    bedeEvent.timestamp = new Date(
      Date.parse(googleEventStart.dateTime)
    ).toJSON();
    bedeEvent.timestampEnd = new Date(
      Date.parse(googleEventEnd.dateTime)
    ).toJSON();
  } else if (bedeEvent.timeType === "date" && googleEventStart.date) {
    bedeEvent.timestamp = new Date(Date.parse(googleEventStart.date)).toJSON();
  } else if (
    bedeEvent.timeType === "day-span" &&
    googleEventStart.date &&
    googleEventEnd.date
  ) {
    bedeEvent.timestamp = new Date(Date.parse(googleEventStart.date)).toJSON();
    bedeEvent.timestampEnd = new Date(Date.parse(googleEventEnd.date)).toJSON();
  } else {
    new Error(
      `event.start and/or event.end lacks information |event.id ${eventId}`
    );
  }

  return bedeEvent;
}

export default getCalendarEvents;
